@use "../../styles/vars.module" as vars;

.container {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: vars.$blue;
  animation-name: increasing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @media screen and (max-width: vars.$small) {
    width: calc(15px * vars.$smallCoef / vars.$small * 100vw);
    height: calc(15px * vars.$smallCoef / vars.$small * 100vw);
  }
}

@keyframes increasing {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3);
  }
}
