@use "../../styles/vars.module" as vars;

.container {
  position: relative;
}

.video {
  max-height: 65svh;
  max-width: 100%;
  &__mirrored {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
