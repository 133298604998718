@use "../../styles/vars.module" as vars;
@import "../../styles/fonts.scss";

.container {
  color: vars.$black;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}

.selected {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: currentColor;
  }
}

.light {
  color: vars.$white;
}
