@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-items: center;
  padding: 10px;
  @media screen and (max-width: vars.$small) {
    grid-template-columns: 1fr;
    gap: adaptSize(10px, vars.$small);
  }
}

.notification {
  justify-self: center;
  grid-column: 1 / -1;
}

.button {
  justify-self: center;
  grid-column: 1 / -1;
}

.alert {
  &--hidden {
    height: 0;
    overflow: hidden;
    padding: 0 !important;
  }
  @media screen and (max-width: vars.$small) {
    &:nth-child(2) {
      grid-row: 3 / 4;
    }
  }
}

.result-img {
  max-height: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: vars.$small) {
    max-height: none;
  }
}
