@use "./styles/vars.module" as vars;
@import "./styles/functions.scss";

@font-face {
  font-family: "SuisseIntl";
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SuisseIntl", sans-serif;
}

body {
  background: vars.$blue2;
  color: vars.$black;
}

.app-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100svh;
  gap: 10px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.max-width {
  max-width: vars.$max_width;
  justify-self: center;
  width: 100%;
  padding: 0 10px;
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    padding: 0 20px;
  }
}

.text {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  justify-self: center;
  margin: 0;
  font-size: 24px;
  color: vars.$black;
  @media screen and (max-width: vars.$small) {
    font-size: 20px;
  }
}

.notification {
  display: flex;
  justify-content: center;
}

.bottom-section {
  align-self: flex-end;
}

.screen-container {
  max-width: 100%;
  height: 100%;
}
