@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.wrapper {
  background: vars.$black;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-self: flex-end;
}

.container {
  color: vars.$white;
  max-width: vars.$max_width;
  width: 100%;
  padding: 5px 20px;
  justify-self: center;
  display: flex;
  gap: 20px;
  font-size: 12px;
  & a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: vars.$blue;
    }
  }
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(5px, vars.$big) adaptSize(20px, vars.$big);
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(5px, vars.$medium) adaptSize(20px, vars.$medium);
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(5px, vars.$small) adaptSize(10px, vars.$small);
    gap: adaptSize(20px, vars.$small);
  }
}
